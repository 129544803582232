import styles from "./aloecam.module.css"



const AloeCamFeed = () => (
    <div className={styles.Aloecam}>
      <br/>
      <br/>
      <br/>
    <h1>Welcome to the home of the aloeCam</h1>
    <img className={styles.img1} src="https://aloecam.altaran.us/"alt='aloeCam'></img>

      
      <br/>
      <br/>
      </div>
      );

      export default AloeCamFeed;
